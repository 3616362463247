import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import { emphasize, styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@mui/icons-material/Home';
import { IFolder } from '../../../../api/FolderApi';
import { getFolderName } from '../../../../util/getFolderName';
interface Props {
  folder: IFolder;
  onNavigation: (folder: IFolder) => void;
}
const StyledBreadcrumb = (styled(Chip)(({
  theme
}) => {
  const backgroundColor = theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: emphasize(backgroundColor, 0.25),
      color: theme.palette.text.primary
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.45)
    }
  };
}) as typeof Chip);
function FolderBreadCrumbs({
  folder,
  onNavigation
}: Props) {
  const {
    t
  } = useTranslation();
  const parents: IFolder[] = [];
  let {
    parent
  } = folder;
  while (parent) {
    parents.push(parent);
    parent = parent.parent;
  }
  return <Breadcrumbs sx={{
    ml: {
      xs: 3,
      md: 2
    }
  }}>
      {parents.reverse().map(p => <StyledBreadcrumb key={p.id} onClick={() => onNavigation(p)} label={getFolderName(p, t)} icon={p.parent === null ? <HomeIcon fontSize="small" /> : undefined} />)}
      <StyledBreadcrumb label={getFolderName(folder, t)} />
    </Breadcrumbs>;
}
export default FolderBreadCrumbs;