import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import { useState, useEffect } from 'react';
const DarkModeToggle_1 = _compiledBlock(_props => <button onClick={_props.v0} style={_props.v1} aria-label={_props.v2}>
      <i className={_props.v3} style={_props.v4} />
    </button>, {
  name: "DarkModeToggle_1"
});
const DarkModeToggle = () => {
  // State to track dark mode
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Load initial value from localStorage
    return localStorage.getItem('darkMode') === 'true';
  });

  // Effect to update the `html` class and save preference
  useEffect(() => {
    const htmlElement = document.documentElement;
    if (isDarkMode) {
      htmlElement.classList.add('dark-mode');
    } else {
      htmlElement.classList.remove('dark-mode');
    }
    // Save preference to localStorage
    localStorage.setItem('darkMode', isDarkMode.toString());
  }, [isDarkMode]);

  // Toggle dark mode
  const handleToggle = () => {
    setIsDarkMode(prevMode => !prevMode);
  };
  return /*@million jsx-skip*/<DarkModeToggle_1 v0={handleToggle} v1={{
    padding: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer'
  }} v2={isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'} v3={`fas ${isDarkMode ? 'fa-sun' : 'fa-moon'}`} v4={{
    color: isDarkMode ? '#FFFFF' : '#333',
    // Bright yellow for light mode, dark gray for dark mode
    fontSize: '1.5rem'
  }} />;
};
export default DarkModeToggle;